import { STERILIZATION_SPECIFICATIONS_FILLTYPES } from "@kraftheinz/common";
import { M as MAINTAIN_PERMISSION } from "./permissions.js";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Fill Types", "search-by": "Name", "ph-search-by": "Search by Fill Types" } }, [_c("url-field", { key: "Name", attrs: { "base-url": "/sterilization-specifications/fill-types", "sorter": true, "data-index": "name", "title": "Fill Types", "clickable": _vm.can(_vm.permissions.maintain) } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListFillTypes",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListFillTypes = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-fill-types" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.fill-types", "api-url": _vm.apiUrl, "create-route": "/sterilization-specifications/fill-types/create", "edit-route": "/sterilization-specifications/fill-types/:id", "list": _vm.ListFillTypes } }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "FillTypes",
  data() {
    return {
      ListFillTypes,
      page: STERILIZATION_SPECIFICATIONS_FILLTYPES,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "filltypes",
          title: "Fill Types",
          path: "/sterilization-specifications/fill-types"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
